@import "src/scss/module";

.ReviewCard {
  --bs-card-border-radius: 0;
  background-color: #{$blue};
  height: 100%;

  :global {
    .card-text {
      display: -webkit-box;
      -webkit-line-clamp: 11;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-img {
      display: flex;
      justify-content: center;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        background-color: #{$gray-400};
      }
    }

    .card-footer {
      background-color: transparent;
      border: none;
    }
  }
}
